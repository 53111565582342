import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  components: any = [
    // {data:
    // [
      {
        name: '5S System',
        desc: `This is a project to implement the 5S methodology at an employers' warehouses. Audit templates are used to build a survey 
        and the score of each survey is used to generate a weighted score for the location.`,
        imgAlt: `5S System Dashboard`,
        imgSrc: `/assets/images/5s-system/dashboard.jpg`,
        slideshowImgURLs: [
          '/assets/images/5s-system/dashboard.jpg', 
          '/assets/images/5s-system/audit.jpg', 
          '/assets/images/5s-system/admin-locations.jpg', 
          '/assets/images/5s-system/admin-templates.jpg', 
          '/assets/images/5s-system/admin-template.jpg'
        ],
        slideshowImgDescs: [
          `<h4>Dashboard</h4>
          The dashboard displays the charts for the weighted aggregate data, along with each audit that was performed. You can filter the 
          data by using the filters, or by drilling down into the charts. You can also create a new audit from this page, or click on 
          existing audits to edit them.`,
          `<h4>Add/Edit Audit</h4>
          This is where you can create or edit audits. As you progress through the audit, the radar chart in the top right updates 
          based on the score. There is also a button to export the audit to PDF.`,
          `<h4>Admin Locations</h4>
          This page lets you manage locations, including the templates the location uses and the weight for each template.`,
          `<h4>Admin Templates</h4>
          The names of templates are managed here. You can click the edit button to navigate to the page to edit all the question for 
          the template.`,
          `<h4>Admin Template</h4>
          You can edit the template questions here.`
        ],
        groupOrSolo: 'Solo',
        technologies: [
          'Backbone.js',
          'Bootstrap',
          'DataTables',
          'Express',
          'FusionCharts',
          'jQuery',
          'MomentJS',
          'MySQL',
          'NodeJS',
          'Select2',
          'Various NodeJS modules'
        ]
      },
      {
        name: 'Account Auditor',
        desc: `This project was initially created for IT staff to audit users based on the information from Active Directory, ADP, 
        and Fonality. Afterwards, an employee directory page was created that all employees have access to.`,
        imgAlt: `Account Auditor Audit`,
        imgSrc: `/assets/images/account-auditor/audit.jpg`,
        slideshowImgURLs: [
          '/assets/images/account-auditor/audit.jpg', 
          '/assets/images/account-auditor/ignore-list.jpg', 
          '/assets/images/account-auditor/directory.jpg'
        ],
        slideshowImgDescs: [
          `<h4>Audit</h4>
          This page displays lists from all three systems. If the record isn't found in either of the other two lists, the whole 
          row is highlighted. If the record is found and there are differences, then the differences are highlighted. If the 
          record is found and matches the other two lists, it's removed from all lists. There is an "Ignore" checkbox for each record 
          that excludes it from the list.`,
          `<h4>Ignore List</h4>
          You can view and un-ignore records from here.`,
          `<h4>Employee Directory</h4>
          All employees' contact information is displayed here.`
        ],
        groupOrSolo: 'Solo',
        technologies: [
          'Backbone.js',
          'Bootstrap',
          'DataTables',
          'Express',
          'jQuery',
          'MomentJS',
          'MSSQL',
          'NodeJS',
          'Various NodeJS modules'
        ]
      },
      {
        name: 'Active Directory Sync',
        desc: `This service syncs data from Active Directory with the company website user database. It was originally created by another 
        developer and I made modifications to it.`,
        slideshowImgURLs: [],
        slideshowImgDescs: [],
        groupOrSolo: 'Group',
        technologies: [
          'Express',
          'MomentJS',
          'NodeJS',
          'MySQL',
          'Various NodeJS modules'
        ]
      },
      {
        name: 'Admin Panel',
        desc: `This website is used to manage users, groups, subgroups, and other internal 
        websites that are proxied to the domain.`,
        imgAlt: `Admin Panel Users`,
        imgSrc: `/assets/images/admin-panel/users.jpg`,
        slideshowImgURLs: [
          '/assets/images/admin-panel/users.jpg', 
          '/assets/images/admin-panel/groups.jpg',
          '/assets/images/admin-panel/components.jpg',
          '/assets/images/admin-panel/security-audits.jpg',
          '/assets/images/admin-panel/data-audits.jpg'
        ],
        slideshowImgDescs: [
          `<h4>Users</h4>
          The users page lets you add, edit, and archive users. There is a NodeJS process that updates 
          the user table with information from Activity Directory, so if the user is an AD user 
          (like in this example), some information isn't editable.`,
          `<h4>Groups</h4>
          The groups page lets you manage groups and subgroups.`,
          `<h4>Components</h4>
          Components are separate websites that get proxied into one domain based on their path. This page
          lets you manage those websites.`,
          `<h4>Security Logs</h4>
          This page lists of all the user successful/failed logins, forgotten password, and forgotten
          username records from the database.`,
          `<h4>Data Logs</h4>
          Any data operations that have been stored to the data logs database table are shown here.`,
        ],
        groupOrSolo: 'Solo',
        technologies: [
          'BackboneJS',
          'Bootstrap',
          'DataTables',
          'Express',
          'jQuery',
          'MomentJS',
          'MySQL',
          'NodeJS',
          'Select2',
          'Various NodeJS modules'
        ]
      },
      {
        name: 'ADP Sync',
        desc: `This is a service that syncs data from the ADP API with a company database. It was created so that the data 
        was accessible in a faster way than using the ADP API directly.`,
        slideshowImgURLs: [],
        slideshowImgDescs: [],
        groupOrSolo: 'Solo',
        technologies: [
          'Express',
          'MomentJS',
          'NodeJS',
          'MSSQL',
          'Various NodeJS modules'
        ]
      },
      {
        name: 'After Hours Calendar',
        desc: `This is a website to manage the after hours schedule.`,
        imgAlt: `After Hours Calendar`,
        imgSrc: `/assets/images/after-hours/calendar.jpg`,
        slideshowImgURLs: [
          '/assets/images/after-hours/calendar.jpg'
        ],
        slideshowImgDescs: [
          `<h4>Calendar</h4>
          You can add a new event to the schedule from the form on the right. Events can be edited/removed by 
          clicking on them or dragging and dropping the event.`
        ],
        groupOrSolo: 'Solo',
        technologies: [
          'Backbone.js',
          'Bootstrap',
          'Express',
          'FullCalendar',
          'jQuery',
          'MomentJS',
          'MySQL',
          'NodeJS',
          'Select2',
          'Various NodeJS modules'
        ]
      },
      {
        name: 'AutoComm',
        desc: `AutoComm is a service that automatically makes calls or texts phone numbers using Twilio 
        when events are triggered. This was originally built by someone else and I made modifications to it.`,
        slideshowImgURLs: [],
        slideshowImgDescs: [],
        groupOrSolo: 'Group',
        technologies: [
          'Express',
          'MomentJS',
          'NodeJS',
          'MSSQL',
          'Twilio',
          'Various NodeJS modules'
        ]
      },
      {
        name: 'AutoComm Manager',
        desc: `The AutoComm contacts and contact lists are managed through this website.`,
        imgAlt: `AutoComm Manager Contacts`,
        imgSrc: '/assets/images/autocomm-mgr/contacts-1.jpg',
        slideshowImgURLs: [
          '/assets/images/autocomm-mgr/contacts-1.jpg', 
          '/assets/images/autocomm-mgr/contacts-2.jpg',
          '/assets/images/autocomm-mgr/contact-groups-1.jpg', 
          '/assets/images/autocomm-mgr/contact-groups-2.jpg'
        ],
        slideshowImgDescs: [
          `<h4>Contacts</h4>
          AutoComm contacts management page.`,
          `<h4>Contacts</h4>
          AutoComm contacts management page.`,
          `<h4>Contact Groups</h4>
          You can manage the contact groups from this page.`,
          `<h4>Contact Groups</h4>
          You can manage the contact groups from this page.`
        ],
        groupOrSolo: 'Solo',
        technologies: [
          'Backbone.js',
          'Bootstrap',
          'DataTables',
          'Express',
          'jQuery',
          'MSSQL',
          'NodeJS',
          'Select2',
          'Various NodeJS modules'
        ]
      },
      {
        name: 'Broker Tools',
        desc: `This website contains tools for the Brokerage department.`,
        imgAlt: `Broker Tools Preferred Lanes`,
        imgSrc: `/assets/images/broker-tools/preferred-lanes.jpg`,
        slideshowImgURLs: [
          '/assets/images/broker-tools/preferred-lanes.jpg', 
          '/assets/images/broker-tools/preferred-lanes-search.jpg',
          '/assets/images/broker-tools/preferred-lanes-search-results.jpg',
          '/assets/images/broker-tools/order-management-new-order.jpg',
          '/assets/images/broker-tools/order-management-order-list.jpg',
        ],
        slideshowImgDescs: [
          `<h4>Preferred Lanes</h4>
          This page lets users enter the lanes a carrier prefers to run. You can use the Select2 inputs or 
          the map to choose origin and destinations.`,
          `<h4>Preferred Lanes Search</h4>
          You can search lanes from this page.`,
          `<h4>Preferred Lanes Search Results</h4>
          This modal displays the results of the search.`,
          `<h4>New/View Order</h4>
          This page lets users enter in order information and it displays the rates from a couple different 
          websites (using their API) along with TMW (trucking management software) rates. A calculator is also 
          available for calculating rates.`,
          `<h4>Order List</h4>
          You can view the orders that have been entered into the system from here. You can filter the list and 
          open an existing order to view more information.`,
        ],
        groupOrSolo: 'Solo',
        technologies: [
          'Backbone.js',
          'Bootstrap',
          'DataTables',
          'Express',
          'FusionCharts',
          'jQuery',
          'MomentJS',
          'MSSQL',
          'NodeJS',
          'Select2',
          'SOAP',
          'Various NodeJS modules'
        ]
      },
      {
        name: 'Camelot Systems Data',
        desc: `This project was created to allow outside access to warehouse management system (WMS) data. This includes reporting 
        and various inventory functions. This website was initially created by others, but I converted it to Backbone.js and MSSQL, 
        re-themed it, and added a couple pages.`,
        imgAlt: `Camelot Systems Database Inventory`,
        imgSrc: `/assets/images/camelot-systems-database/inventory.jpg`,
        slideshowImgURLs: [
          '/assets/images/camelot-systems-database/inventory.jpg', 
          '/assets/images/camelot-systems-database/plant-inventory.jpg',
          '/assets/images/camelot-systems-database/activity.jpg', 
          '/assets/images/camelot-systems-database/history.jpg', 
          '/assets/images/camelot-systems-database/asn.jpg', 
          '/assets/images/camelot-systems-database/material-reorder.jpg', 
          '/assets/images/camelot-systems-database/edi-tracking.jpg', 
          '/assets/images/camelot-systems-database/quantity-change-1.jpg', 
          '/assets/images/camelot-systems-database/quantity-change-2.jpg', 
          '/assets/images/camelot-systems-database/new-item.jpg', 
          '/assets/images/camelot-systems-database/edit-item.jpg', 
          '/assets/images/camelot-systems-database/reorder-shipment-schedule.jpg', 
          '/assets/images/camelot-systems-database/pending-shipment-report.jpg', 
          '/assets/images/camelot-systems-database/asn-verification.jpg', 
          '/assets/images/camelot-systems-database/consolidated-bol.jpg', 
          '/assets/images/camelot-systems-database/cycle-count.jpg', 
          '/assets/images/camelot-systems-database/reorder-shipment-report.jpg', 
          '/assets/images/camelot-systems-database/supplier-order-summary.jpg', 
          '/assets/images/camelot-systems-database/supplier-receipt-summary.jpg', 
          '/assets/images/camelot-systems-database/item-activity-report.jpg', 
        ],
        slideshowImgDescs: [
          `<h4>Item/Lot/Piece Inventory</h4>`,
          `<h4>Plant Inventory</h4>`,
          `<h4>Activity</h4>`,
          `<h4>History</h4>`,
          `<h4>Advanced Shipping Notification</h4>
          This page allows clients who don't use EDI to create orders in the WMS.`,
          `<h4>Material Reorder</h4>
          You can reorder items from a plant on this page.`,
          `<h4>EDI Tracking</h4>`,
          `<h4>Quantity Change</h4>
          The minimum and critical item levels are managed here. Those levels are used to send out notifications about low inventory 
          levels to the warehouse employees.`,
          `<h4>Quantity Change</h4>
          The minimum and critical item levels are managed here. Those levels are used to send out notifications about low inventory 
          levels to the warehouse employees.`,
          `<h4>New Item</h4>`,
          `<h4>Edit Item</h4>`,
          `<h4>Reorder Shipment Schedule</h4>
          This is one of the pages I created. It lets you create a job schedule that has multiple manufactured lines and days associated 
          with it. A SQL Server job checks to see if it's time to run the job, and when it is, the lines and warehouse for that schedule 
          are sent to a stored procedure that a co-worker wrote.`,
          `<h4>Pending Shipment Report</h4>`,
          `<h4>ASN Verification Report</h4>`,
          `<h4>Consolidated BOL</h4>`,
          `<h4>Cycle Count</h4>`,
          `<h4>Reorder Shipment Report</h4>
          This shows the results of the reorder shipment scheduled jobs.`,
          `<h4>Supplier Order Summary</h4>`,
          `<h4>Supplier Receipt Summary</h4>`,
          `<h4>Item Activity Report</h4>`,
        ],
        groupOrSolo: 'Group',
        technologies: [
          'Backbone.js',
          'Bootstrap',
          'DataTables',
          'Express',
          'jQuery',
          'MomentJS',
          'MSSQL',
          'NodeJS',
          'Select2',
          'Various NodeJS modules'
        ]
      },
      {
        name: 'Change Log',
        desc: `This is site to view all the recent changes made to any of the projects in the git repository.`,
        imgAlt: `Change Log list`,
        imgSrc: `/assets/images/change-log/list.jpg`,
        slideshowImgURLs: [
          '/assets/images/change-log/list.jpg'
        ],
        slideshowImgDescs: [
          `<h4>List</h4>
          The list of changes are sorted by most recent by default. This helps the support group to determine if 
          there was a recent change related to a help desk ticket that came in.`
        ],
        groupOrSolo: 'Solo',
        technologies: [
          'Backbone.js',
          'Bootstrap',
          'DataTables',
          'Express',
          'GitLab API',
          'jQuery',
          'MomentJS',
          'NodeJS',
          'Various NodeJS modules'
        ]
      },
      {
        name: 'Change Management System',
        desc: `This system was created for the IT department to approve, request, and track changes made to internal systems.`,
        imgAlt: `Change Management System List`,
        imgSrc: `/assets/images/change-management-system/list.jpg`,
        slideshowImgURLs: [
          '/assets/images/change-management-system/list.jpg', 
          '/assets/images/change-management-system/entry.jpg'
        ],
        slideshowImgDescs: [
          `<h4>Entries</h4>
          Here you can view all the change entries, mark start and end times, leave comments, and click the view button for more 
          details about the entry.`,
          `<h4>Entry</h4>`
        ],
        groupOrSolo: 'Solo',
        technologies: [
          'Backbone.js',
          'Bootstrap',
          'DataTables',
          'Express',
          'jQuery',
          'MomentJS',
          'MSSQL',
          'NodeJS',
          'Select2',
          'Various NodeJS modules'
        ]
      },
      {
        name: 'Collections Database',
        desc: `This website ties information from an accounting, document indexing, and trucking management database together for the 
          accounting department so that they have all the information in one place and don't have to open multiple programs.`,
        imgAlt: `Collections Database Invoices`,
        imgSrc: `/assets/images/collections-database/invoices-1.jpg`,
        slideshowImgURLs: [
          '/assets/images/collections-database/invoices-1.jpg', 
          '/assets/images/collections-database/invoices-2.jpg', 
          '/assets/images/collections-database/admin-1.jpg', 
          '/assets/images/collections-database/admin-2.jpg', 
        ],
        slideshowImgDescs: [
          `<h4>Invoices</h4>`,
          `<h4>Invoices</h4>`,
          `<h4>Admin</h4>`,
          `<h4>Admin</h4>`
        ],
        groupOrSolo: 'Solo',
        technologies: [
          'Backbone.js',
          'Bootstrap',
          'DataTables',
          'Express',
          'jQuery',
          'MomentJS',
          'MSSQL',
          'NodeJS',
          'Select2',
          'Various NodeJS modules'
        ]
      },
      {
        name: 'Core/API',
        desc: `This is a service that is used to connect all the websites together. Whenever a website is 
        initially attempts to get rendered, it gets sent through this first so that some core functionality 
        is included. This was originally built by someone else but I heavily modified it.`,
        slideshowImgURLs: [],
        slideshowImgDescs: [],
        groupOrSolo: 'Group',
        technologies: [
          'Bootstrap',
          'Express',
          'jQuery',
          'MomentJS',
          'NodeJS',
          'MySQL',
          'MSSQL',
          'Various NodeJS modules'
        ]
      },
      {
        name: 'Corrective Action System',
        desc: `The Corrective Action System is used to keep track of issues that occur in any business group 
        so that the issue, root cause, and solution can be reported on.`,
        imgAlt: `Corrective Action System List`,
        imgSrc: `/assets/images/corrective-action-system/list.jpg`,
        slideshowImgURLs: [
          '/assets/images/corrective-action-system/list.jpg', 
          '/assets/images/corrective-action-system/report-1.jpg', 
          '/assets/images/corrective-action-system/report-2.jpg', 
          '/assets/images/corrective-action-system/report-3.jpg', 
          '/assets/images/corrective-action-system/report-4.jpg', 
          '/assets/images/corrective-action-system/report-5.jpg', 
          '/assets/images/corrective-action-system/accident-free-dashboard.jpg', 
          '/assets/images/corrective-action-system/admin-issue-types.jpg', 
          '/assets/images/corrective-action-system/admin-division-teams.jpg', 
          '/assets/images/corrective-action-system/admin-power-users.jpg', 
          '/assets/images/corrective-action-system/admin-safety-approval-time-report.jpg', 
          '/assets/images/corrective-action-system/admin-time-to-close-report.jpg'
        ],
        slideshowImgDescs: [
          `<h4>List</h4>
          This is a list of all the corrective action reports in the system. You can filter the table 
          on all of the columns.`,
          `<h4>New/Create Report</h4>
          You can add a team of people to each report to help verify the issue and root cause. You can also 
          export an existing report to PDF.`,
          `<h4>New/Create Report</h4>
          Attachments can be added to each section. If the issue type of the report is safety related, 
          the incident information panel is shown.`,
          `<h4>New/Create Report</h4>
          Information like, problem impact, interim containment, and root cause are required to be 
          entered. There are templates available to use also.`,
          `<h4>New/Create Report</h4>
          Each section displays the last date/time the section was updated, including who it was updated 
          by. Verification of the report is required after a period of time has passed after it has been closed.`,
          `<h4>New/Create Report</h4>
          The "Closure" section is filled out by the approver when they have determined that adequate 
          measures have been taken to prevent the issue from happening in the future.`,
          `<h4>Accident Free Dashboard</h4>
          This dashboard shows the number of days that have passed since a report has been entered last.`,
          `<h4>Issue Types</h4>
          This is an Admin page (only accessible by Admins) where issue types are managed.`,
          `<h4>Division Teams</h4>
          This is another Admin page for managing division teams. Based on the division selected when 
          creating a report, a different team is selected by default.`,
          `<h4>Power Users</h4>
          This Admin page lets you manage power users. Based on the division, a randomly selected 
          power user is added to the report team members.`,
          `<h4>Safety Approval Time Report</h4>
          This Admin page displays the time it took for the report to pass through safety approval.`,
          `<h4>Time To Close Report</h4>
          This Admin page displays the time it took for the report to be closed.`,
        ],
        groupOrSolo: 'Solo',
        technologies: [
          'Backbone.js',
          'Bootstrap',
          'DataTables',
          'Express',
          'jQuery',
          'MomentJS',
          'NodeJS',
          'Select2',
          'Various NodeJS modules'
        ]
      },
      {
        name: 'Dashboard',
        desc: `This a landing page with tabbed dashboards where you can add widgets for all the connected websites.`,
        imgAlt: `Dashboard`,
        imgSrc: `/assets/images/dashboard/dashboard-1.jpg`,
        slideshowImgURLs: [
          '/assets/images/dashboard/dashboard-1.jpg', 
          '/assets/images/dashboard/dashboard-2.jpg', 
          '/assets/images/dashboard/dashboard-3.jpg', 
        ],
        slideshowImgDescs: [
          `<h4>Dashboard</h4>`,
          `<h4>Dashboard</h4>`,
          `<h4>Dashboard</h4>`
        ],
        groupOrSolo: 'Group',
        technologies: [
          'Backbone.js',
          'Bootstrap',
          'DataTables',
          'Express',
          'FusionCharts',
          'jQuery',
          'MomentJS',
          'NodeJS',
          'Select2',
          'Various NodeJS modules'
        ]
      },
      {
        name: 'Department User Management',
        desc: `This site was created for the brokerage and sales department to create/edit accounts for their customers.`,
        imgAlt: `Department User Management Users`,
        imgSrc: `/assets/images/dept-user-mgmt/users.jpg`,
        slideshowImgURLs: [
          '/assets/images/dept-user-mgmt/users.jpg'
        ],
        slideshowImgDescs: [
          `<h4>Users</h4>`
        ],
        groupOrSolo: 'Solo',
        technologies: [
          'Backbone.js',
          'Bootstrap',
          'DataTables',
          'Express',
          'jQuery',
          'MomentJS',
          'NodeJS',
          'Select2',
          'Various NodeJS modules'
        ]
      },
      {
        name: 'Driver Recruitment',
        desc: `This dashboard contains pages for reporting on driver hire and retention. It contains a large amount of FusionCharts 
          to display the data.`,
        imgAlt: `Driver Recruitment Fallout Metrics`,
        imgSrc: `/assets/images/driver-recruitment/fallout-metrics.jpg`,
        slideshowImgURLs: [
          '/assets/images/driver-recruitment/fallout-metrics.jpg',
          '/assets/images/driver-recruitment/cost-per-hire-1.jpg',
          '/assets/images/driver-recruitment/cost-per-hire-2.jpg',
          '/assets/images/driver-recruitment/turnover-report-1.jpg',
          '/assets/images/driver-recruitment/turnover-report-2.jpg',
          '/assets/images/driver-recruitment/source-of-application.jpg',
          '/assets/images/driver-recruitment/admin.jpg'
        ],
        slideshowImgDescs: [
          `<h4>Fallout Metrics</h4>`,
          `<h4>Cost Per Hire</h4>`,
          `<h4>Cost Per Hire</h4>`,
          `<h4>Turnover Report</h4>`,
          `<h4>Turnover Report</h4>`,
          `<h4>Source of Application</h4>`,
          `<h4>Admin</h4>`,
        ],
        groupOrSolo: 'Solo',
        technologies: [
          'Backbone.js',
          'Bootstrap',
          'DataTables',
          'Express',
          'FusionCharts',
          'jQuery',
          'MomentJS',
          'NodeJS',
          'Select2',
          'Various NodeJS modules'
        ]
      },
      {
        name: 'Employee Onboarding',
        desc: `This system is to help streamline the employee onboarding/termination process. HR enters the new employee's information and 
          software/hardware requirements. Then, IT is notified so that they can set them up. Terminations are entered by HR so that IT 
          can make sure that the employee's access has been revoked to the correct systems.`,
        imgAlt: `Employee Onboarding List`,
        imgSrc: `/assets/images/employee-onboarding/list.jpg`,
        slideshowImgURLs: [
          '/assets/images/employee-onboarding/list.jpg', 
          '/assets/images/employee-onboarding/entry.jpg', 
          '/assets/images/employee-onboarding/admin.jpg'
        ],
        slideshowImgDescs: [
          `<h4>List</h4>`,
          `<h4>Entry</h4>`,
          `<h4>Admin</h4>`,
        ],
        groupOrSolo: 'Solo',
        technologies: [
          'Backbone.js',
          'Bootstrap',
          'DataTables',
          'Express',
          'jQuery',
          'MomentJS',
          'NodeJS',
          'Select2',
          'Various NodeJS modules'
        ]
      },
      {
        name: 'HINT BOLs',
        desc: `This page was created to give outside customers to BOL documents. The BOLs are printed in the warehouse management system 
          and this system reads the directory where copies of these documents are stored.`,
        imgAlt: `HINT BOLs`,
        imgSrc: `/assets/images/hint-bols/list.jpg`,
        slideshowImgURLs: [
          '/assets/images/hint-bols/list.jpg'
        ],
        slideshowImgDescs: [
          `<h4>List</h4>`
        ],
        groupOrSolo: 'Solo',
        technologies: [
          'Backbone.js',
          'Bootstrap',
          'DataTables',
          'Express',
          'jQuery',
          'MomentJS',
          'NodeJS',
          'Select2',
          'Various NodeJS modules'
        ]
      },
      {
        name: 'HydroFresh HPP',
        desc: `A project to provide functionality that the warehouse management system didn't provide. Each pallet is reviewed to make 
          sure that the correct cases, and their information, are on the pallet.`,
        imgAlt: `HydroFresh HPP Case Verification`,
        imgSrc: `/assets/images/hydrofresh-hpp/case-verification.jpg`,
        slideshowImgURLs: [
          '/assets/images/hydrofresh-hpp/case-verification.jpg'
        ],
        slideshowImgDescs: [
          `<h4>Case Verification</h4>`
        ],
        groupOrSolo: 'Group',
        technologies: [
          'Backbone.js',
          'Bootstrap',
          'DataTables',
          'Express',
          'jQuery',
          'MomentJS',
          'NodeJS',
          'Select2',
          'Various NodeJS modules'
        ]
      },
      {
        name: 'Packaging Dashboard',
        desc: `This dashboard was created by someone else and I converted it to BackboneJS and added new pages.`,
        imgAlt: `Packaging Dashboard`,
        imgSrc: `/assets/images/packaging-dashboard/dashboard.jpg`,
        slideshowImgURLs: [
          '/assets/images/packaging-dashboard/dashboard.jpg', 
          '/assets/images/packaging-dashboard/production-1.jpg', 
          '/assets/images/packaging-dashboard/production-2.jpg', 
          '/assets/images/packaging-dashboard/downtime-1.jpg', 
          '/assets/images/packaging-dashboard/downtime-2.jpg', 
          '/assets/images/packaging-dashboard/entries.jpg', 
          '/assets/images/packaging-dashboard/entry.jpg', 
          '/assets/images/packaging-dashboard/admin-clients.jpg', 
          '/assets/images/packaging-dashboard/admin-issue-categories.jpg', 
          '/assets/images/packaging-dashboard/admin-issues.jpg', 
          '/assets/images/packaging-dashboard/admin-machines.jpg', 
          '/assets/images/packaging-dashboard/admin-operators.jpg', 
          '/assets/images/packaging-dashboard/admin-settings.jpg', 
          '/assets/images/packaging-dashboard/admin-standards.jpg'
        ],
        slideshowImgDescs: [
          `<h4>Dashboard</h4>`,
          `<h4>Production</h4>`,
          `<h4>Production</h4>`,
          `<h4>Downtime</h4>`,
          `<h4>Downtime</h4>`,
          `<h4>Entries</h4>`,
          `<h4>Entry</h4>`,
          `<h4>Admin Clients</h4>`,
          `<h4>Admin Issue Categories</h4>`,
          `<h4>Admin Issues</h4>`,
          `<h4>Admin Machines</h4>`,
          `<h4>Admin Operators</h4>`,
          `<h4>Admin Settings</h4>`,
          `<h4>Admin Standards</h4>`,
        ],
        groupOrSolo: 'Group',
        technologies: [
          'Backbone.js',
          'Bootstrap',
          'DataTables',
          'Express',
          'jQuery',
          'MomentJS',
          'NodeJS',
          'Select2',
          'Various NodeJS modules'
        ]
      },
      {
        name: 'POMAT',
        desc: `This system provides users the ability to create different types of labels. It was originally written in C#/.NET by 
          someone else and only included a few pages. I rewrote it in Javascript and added quite a few more pages.`,
        imgAlt: `POMAT Labeling`,
        imgSrc: `/assets/images/pomat/labeling.jpg`,
        slideshowImgURLs: [
          '/assets/images/pomat/labeling.jpg', 
          '/assets/images/pomat/reprint.jpg', 
          '/assets/images/pomat/partial-pallet-print.jpg', 
          '/assets/images/pomat/basic-lp-print.jpg', 
          '/assets/images/pomat/bin-group-print.jpg', 
          '/assets/images/pomat/bin-location-print.jpg', 
          '/assets/images/pomat/receipt-lps-print.jpg', 
          '/assets/images/pomat/upc-print.jpg', 
          '/assets/images/pomat/handheld-logins.jpg', 
          '/assets/images/pomat/free-print.jpg', 
          '/assets/images/pomat/fix-item.jpg', 
          '/assets/images/pomat/admin-file-name-formats.jpg', 
          '/assets/images/pomat/admin-printers.jpg', 
          '/assets/images/pomat/admin-templates.jpg', 
          '/assets/images/pomat/admin-warehouses.jpg'
        ],
        slideshowImgDescs: [
          `<h4>Labeling</h4>`,
          `<h4>Reprint</h4>`,
          `<h4>Partial Pallet Print</h4>`,
          `<h4>Basic LP Print</h4>`,
          `<h4>Bin Group Print</h4>`,
          `<h4>Bin Location Print</h4>`,
          `<h4>Receipt LPs Print</h4>`,
          `<h4>UPC Print</h4>`,
          `<h4>Handheld Logins</h4>`,
          `<h4>Free Print</h4>`,
          `<h4>Fix Item</h4>`,
          `<h4>Admin File Name Formats</h4>`,
          `<h4>Admin Printers</h4>`,
          `<h4>Admin Templates</h4>`,
          `<h4>Admin Warehouses</h4>`,
        ],
        groupOrSolo: 'Group',
        technologies: [
          'Backbone.js',
          'Bootstrap',
          'DataTables',
          'Express',
          'jQuery',
          'MomentJS',
          'NodeJS',
          'Select2',
          'Various NodeJS modules'
        ]
      },
      {
        name: 'Reporting',
        desc: `This includes a variety of dashboards and reports for different divisions of the company.`,
        imgAlt: `Reporting Brokerage Metrics`,
        imgSrc: `/assets/images/reporting/brokerage-metrics-1.jpg`,
        slideshowImgURLs: [
          '/assets/images/reporting/brokerage-metrics-1.jpg', 
          '/assets/images/reporting/brokerage-metrics-2.jpg', 
          '/assets/images/reporting/individual-procurement-metrics-1.jpg', 
          '/assets/images/reporting/individual-procurement-metrics-2.jpg', 
          '/assets/images/reporting/individual-sales-metrics-1.jpg', 
          '/assets/images/reporting/individual-sales-metrics-2.jpg', 
          '/assets/images/reporting/carrier-records.jpg', 
          '/assets/images/reporting/order-lane-history.jpg', 
          '/assets/images/reporting/it-scoreboard-1.jpg', 
          '/assets/images/reporting/it-scoreboard-2.jpg', 
          '/assets/images/reporting/it-scoreboard-3.jpg', 
          '/assets/images/reporting/it-support-dashboard-1.jpg', 
          '/assets/images/reporting/it-support-dashboard-2.jpg', 
          '/assets/images/reporting/support-scorecards.jpg', 
          '/assets/images/reporting/company-search.jpg', 
          '/assets/images/reporting/contact-notes-search.jpg', 
          '/assets/images/reporting/driver-scorecards-1.jpg', 
          '/assets/images/reporting/driver-scorecards-2.jpg', 
          '/assets/images/reporting/driver-scorecards-3.jpg', 
          '/assets/images/reporting/driver-scorecards-4.jpg', 
          '/assets/images/reporting/driver-notes.jpg', 
          '/assets/images/reporting/driver-survey.jpg', 
          '/assets/images/reporting/driver-scorecards-mileage.jpg', 
          '/assets/images/reporting/manage-driver-idle-time.jpg', 
          '/assets/images/reporting/scan-activity.jpg'
        ],
        slideshowImgDescs: [
          `<h4>Brokerage Metrics</h4>`,
          `<h4>Brokerage Metrics</h4>`,
          `<h4>Individual Procurement Metrics</h4>`,
          `<h4>Individual Procurement Metrics</h4>`,
          `<h4>Individual Sales Metrics</h4>`,
          `<h4>Individual Sales Metrics</h4>`,
          `<h4>Carrier Records</h4>`,
          `<h4>Order Lane History</h4>`,
          `<h4>IT Scoreboard</h4>`,
          `<h4>IT Scoreboard</h4>`,
          `<h4>IT Scoreboard</h4>`,
          `<h4>IT Support Dashboard</h4>`,
          `<h4>IT Support Dashboard</h4>`,
          `<h4>Support Scorecards</h4>`,
          `<h4>Company Search</h4>`,
          `<h4>Contact Notes Search</h4>`,
          `<h4>Driver Scorecards</h4>`,
          `<h4>Driver Scorecards</h4>`,
          `<h4>Driver Scorecards</h4>`,
          `<h4>Driver Scorecards</h4>`,
          `<h4>Driver Notes</h4>`,
          `<h4>Driver Survey</h4>`,
          `<h4>Driver Scorecards Mileage</h4>`,
          `<h4>Manage Driver Idle Time</h4>`,
          `<h4>Scan Activity</h4>`
        ],
        groupOrSolo: 'Solo',
        technologies: [
          'Backbone.js',
          'Bootstrap',
          'DataTables',
          'Express',
          'jQuery',
          'MomentJS',
          'NodeJS',
          'Select2',
          'Various NodeJS modules'
        ]
      },
      {
        name: 'Trucking',
        desc: `A collection of different trucking related webpages live here. The backhaul board was initially created by someone 
          else and I modified it and added functionality. The rest of the pages were created by me.`,
        imgAlt: `Trucking Backhaul Board`,
        imgSrc: `/assets/images/trucking/backhaul-board-1.jpg`,
        slideshowImgURLs: [
          '/assets/images/trucking/backhaul-board-1.jpg', 
          '/assets/images/trucking/backhaul-board-2.jpg', 
          '/assets/images/trucking/backhaul-board-3.jpg', 
          '/assets/images/trucking/backhaul-board-4.jpg', 
          '/assets/images/trucking/capacity-dashboard-1.jpg', 
          '/assets/images/trucking/capacity-dashboard-2.jpg', 
          '/assets/images/trucking/capacity-dashboard-3.jpg', 
          '/assets/images/trucking/load-tracking.jpg', 
          '/assets/images/trucking/rejected-loads.jpg', 
          '/assets/images/trucking/rejected-load-entry.jpg', 
          '/assets/images/trucking/fuel-reporting-dashboard.jpg', 
          '/assets/images/trucking/fuel-reporting-expedite-report.jpg', 
          '/assets/images/trucking/fuel-reporting-main-report.jpg', 
          '/assets/images/trucking/fuel-reporting-misc-entry.jpg', 
          '/assets/images/trucking/fuel-reporting-mpg-report-management.jpg', 
          '/assets/images/trucking/fuel-reporting-mpg-report.jpg', 
          '/assets/images/trucking/fuel-reporting-reefer-report.jpg', 
          '/assets/images/trucking/fuel-reporting-slattery-entry.jpg', 
          '/assets/images/trucking/fuel-reporting-slattery-report.jpg', 
          '/assets/images/trucking/fuel-reporting-truck-types.jpg', 
          '/assets/images/trucking/fuel-reporting-trucks.jpg', 
          '/assets/images/trucking/pop-fuel-reporting.jpg', 
          '/assets/images/trucking/customer-scorecards-list.jpg', 
          '/assets/images/trucking/customer-scorecards-survey.jpg', 
          '/assets/images/trucking/load-commitment-search.jpg', 
          '/assets/images/trucking/load-commitment-management.jpg', 
        ],
        slideshowImgDescs: [
          `<h4>Backhaul Board</h4>`,
          `<h4>Backhaul Board</h4>`,
          `<h4>Backhaul Board</h4>`,
          `<h4>Backhaul Board</h4>`,
          `<h4>Capacity Dashboard</h4>`,
          `<h4>Capacity Dashboard</h4>`,
          `<h4>Capacity Dashboard</h4>`,
          `<h4>Load Tracking</h4>`,
          `<h4>Rejected Loads</h4>`,
          `<h4>Rejected Loads Entry</h4>`,
          `<h4>Fuel Reporting Dashboard</h4>`,
          `<h4>Fuel Reporting Expedite Report</h4>`,
          `<h4>Fuel Reporting Main Report</h4>`,
          `<h4>Fuel Reporting Misc Entry</h4>`,
          `<h4>Fuel Reporting MPG Report Management</h4>`,
          `<h4>Fuel Reporting MPG Report</h4>`,
          `<h4>Fuel Reporting Reefer Report</h4>`,
          `<h4>Fuel Reporting Slattery Entry</h4>`,
          `<h4>Fuel Reporting Slattery Report</h4>`,
          `<h4>Fuel Reporting Truck Types</h4>`,
          `<h4>Fuel Reporting Trucks</h4>`,
          `<h4>POP Fuel Reporting</h4>`,
          `<h4>Customer Scorecards List</h4>`,
          `<h4>Customer Scorecards Survey</h4>`,
          `<h4>Load Commitment Search</h4>`,
          `<h4>Load Commitment Management</h4>`
        ],
        groupOrSolo: 'Group',
        technologies: [
          'Backbone.js',
          'Bootstrap',
          'DataTables',
          'Express',
          'FusionCharts',
          'jQuery',
          'MomentJS',
          'NodeJS',
          'Select2',
          'Various NodeJS modules'
        ]
      },
      {
        name: 'Trucking Customer Portal',
        desc: `This is a page that gives customers access to their invoices and other data.`,
        imgAlt: `Trucking Customer Portal Invoices`,
        imgSrc: `/assets/images/trucking-customer-portal/invoices.jpg`,
        slideshowImgURLs: [
          '/assets/images/trucking-customer-portal/invoices.jpg'
        ],
        slideshowImgDescs: [
          `<h4>Invoices</h4>`
        ],
        groupOrSolo: 'Solo',
        technologies: [
          'Backbone.js',
          'Bootstrap',
          'DataTables',
          'Express',
          'jQuery',
          'MomentJS',
          'NodeJS',
          'Select2',
          'Various NodeJS modules'
        ]
      },
      {
        name: 'Wrike Sync',
        desc: `This service syncs data from the Wrike API with a company database. It allows faster access 
        to Wrike data than using the Wrike API directly.`,
        slideshowImgURLs: [],
        slideshowImgDescs: [],
        groupOrSolo: 'Solo',
        technologies: [
          'Express',
          'MomentJS',
          'NodeJS',
          'MSSQL',
          'Various NodeJS modules'
        ]
      },
      {
        name: 'Yard Management System',
        desc: `This system is used to manage warehouse yard locations and give carriers visibility into where their trailers are 
          in the yard.`,
        imgAlt: `Yard Management System Docks`,
        imgSrc: `/assets/images/yard-management-system/docks-1.jpg`,
        slideshowImgURLs: [
          '/assets/images/yard-management-system/docks-1.jpg', 
          '/assets/images/yard-management-system/docks-2.jpg', 
          '/assets/images/yard-management-system/docks-3.jpg', 
          '/assets/images/yard-management-system/lots.jpg', 
          '/assets/images/yard-management-system/trailers.jpg', 
          '/assets/images/yard-management-system/map.jpg', 
          '/assets/images/yard-management-system/inbound-schedule.jpg', 
          '/assets/images/yard-management-system/outbound-schedule.jpg', 
          '/assets/images/yard-management-system/history-trailer-updates.jpg', 
          '/assets/images/yard-management-system/history-trailer-arrivals.jpg', 
          '/assets/images/yard-management-system/history-overlimit.jpg', 
          '/assets/images/yard-management-system/history-inbound-schedule.jpg', 
          '/assets/images/yard-management-system/history-outbound-schedule.jpg', 
          '/assets/images/yard-management-system/history-admin.jpg', 
          '/assets/images/yard-management-system/report-late-to-appointment.jpg', 
          '/assets/images/yard-management-system/report-no-show.jpg', 
          '/assets/images/yard-management-system/report-shipment-status.jpg', 
          '/assets/images/yard-management-system/report-spotter.jpg', 
          '/assets/images/yard-management-system/report-trailer-aging.jpg', 
          '/assets/images/yard-management-system/admin-carriers.jpg', 
          '/assets/images/yard-management-system/admin-clients.jpg', 
          '/assets/images/yard-management-system/admin-client-edit.jpg', 
          '/assets/images/yard-management-system/admin-dock-sections.jpg', 
          '/assets/images/yard-management-system/admin-docks.jpg', 
          '/assets/images/yard-management-system/admin-locations.jpg', 
          '/assets/images/yard-management-system/admin-lots.jpg', 
          '/assets/images/yard-management-system/admin-spotters.jpg'
        ],
        slideshowImgDescs: [
          `<h4>Docks</h4>`,
          `<h4>Docks</h4>`,
          `<h4>Docks</h4>`,
          `<h4>Lots</h4>`,
          `<h4>Trailers</h4>`,
          `<h4>Map</h4>`,
          `<h4>Inbound Schedule</h4>`,
          `<h4>Outbound Schedule</h4>`,
          `<h4>Trailer Updates History</h4>`,
          `<h4>Trailer Arrivals History</h4>`,
          `<h4>Over Limit Trailers History</h4>`,
          `<h4>Inbound Schedule History</h4>`,
          `<h4>Outbound Schedule History</h4>`,
          `<h4>Admin History</h4>`,
          `<h4>Late To Appointment Report</h4>`,
          `<h4>No Show Report</h4>`,
          `<h4>Shipment Status Report</h4>`,
          `<h4>Spotter Report</h4>`,
          `<h4>Trailer Aging Report</h4>`,
          `<h4>Admin Carriers</h4>`,
          `<h4>Admin Clients</h4>`,
          `<h4>Admin Edit Client</h4>`,
          `<h4>Admin Dock Sections</h4>`,
          `<h4>Admin Docks</h4>`,
          `<h4>Admin Locations</h4>`,
          `<h4>Admin Lots</h4>`,
          `<h4>Admin Spotters</h4>`,
        ],
        groupOrSolo: 'Solo',
        technologies: [
          'Backbone.js',
          'Bootstrap',
          'DataTables',
          'Express',
          'FullCalendar',
          'jQuery',
          'MomentJS',
          'NodeJS',
          'Select2',
          'Various NodeJS modules'
        ]
      },
  //   ]
  // }
  ];
  selectedComponent: any;
  currentSlideDesc = '';

  constructor() { }

  ngOnInit() {}

  showModal(modal, component){
    this.selectedComponent = {...component};
    console.log(this.selectedComponent);
    modal.show();
  }

  onModalClosed(){
    this.selectedComponent = undefined;
  }

  updateSlideText(index){
    if((this.selectedComponent.slideshowImgDescs.length - 1) >= index){
      this.currentSlideDesc = this.selectedComponent.slideshowImgDescs[index];
    }
    else {
      this.currentSlideDesc = '';
    }
  }
}
